/*
Version: 1.0
Author: Clearsquare LLC
Author URI: https://www.clearsquare.co/.
*/
/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f1f5f9;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(203, 213, 225, 0.7);
    border-radius: 100vh;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(203, 213, 225, 1);
  }
}

body,
html {
  width: 100%;
  /*line-height: 18px;*/
  margin: 0 auto !important;
  padding: 0 !important;
  /* font-family: "Lato", sans-serif !important; */
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  font-size: 14px;
  position: relative;
  z-index: 0;
  /* overflow-x: hidden; */
}

body {
  scrollbar-gutter: stable;
}

body > iframe#webpack-dev-server-client-overlay {
  display: none;
}

*:focus {
  outline: none !important;
  outline-offset: none !important;
  outline-offset: 0 !important;
}

.main_item-background {
  background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(90deg, rgba(243, 251, 255, 0.4) 0%, rgba(208, 255, 255, 0.4) 15.74%, rgba(255, 214, 255, 0.4) 37.11%, rgba(227, 213, 255, 0.4) 57.27%, rgba(218, 233, 253, 0.4) 82.44%, rgba(230, 216, 254, 0.4) 100%);
}

input[type="color"] {
  -webkit-appearance: none;
  border: 1px solid rgba(181, 181, 181, 0.121);
  border-radius: 3px;
  overflow: hidden;
  width: 21px;
  height: 20px;
  margin-right: 4px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 3px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.custom-dropdown-content {
  height: 0;
}
.custom-drodown:hover .custom-dropdown-content {
  height: auto;
}
